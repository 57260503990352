export const setupClickEvent = () => {
    document.addEventListener('click', (elm) => {
        if (elm.target.tagName === 'A') {
            const dataLayer = (window === null || window === void 0 ? void 0 : window.dataLayer) || [];
            dataLayer.push({
                event: 'link_click',
                href: elm.target.href
            });
        }
    }, false);
};
