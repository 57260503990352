<script lang="ts">
  const socials = [
    {
      link: 'https://github.com/Toxiapo',
      label: '@github'
    },
    {
      link: 'https://www.linkedin.com/in/jy-qiu',
      label: '@linkedin'
    }
  ];
</script>

<section class="content">
  <h1>Hi! I am Jiayong Qiu.</h1>
  <h2>Software Engineer @ NYC</h2>
  <h3>
    <em>React &#8226; Hybrid &#8226; Fullstack development </em>enthusiast.
  </h3>
  <ul>
    {#each socials as social, i}
      <li>
        <a href={social.link} target="_blank" rel="noreferrer">{social.label}</a
        >
      </li>
    {/each}
    <li>
      <a href="mailto:toxiapo@gmail.com">@contact</a>
    </li>
  </ul>
</section>

<style scoped>
  * {
    margin: 0;
  }

  .content {
    color: #212121;
    line-height: 1.4;
  }

  h1 {
    font-size: 1.25rem;
  }

  h2 {
    font-size: 1rem;
  }

  h3 {
    margin-top: 1rem;
    font-size: 0.85rem;
  }

  ul {
    margin: 0.6rem 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2px;
  }

  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
</style>
