import App from './App.svelte';
import {setupClickEvent} from "./analytic"

setupClickEvent();

const app = new App({
  target: document.body
});

export default app;
