<script lang="ts">
  import Introduction from './../components/Introduction.svelte';
</script>

<main>
  <Introduction />
</main>

<style>
  main {
    padding: 1em;
    display: flex;
    align-items: start;
  }
</style>
